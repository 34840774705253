import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';


const ErrorMessage = ({
  error,
  touched,
}) => ((!touched || !error)
  ? null
  : (<ul className="form__error">{[].concat(error).map((err) => (<li key={err}>{err}</li>))}</ul>));

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ]),
  input: PropTypes.shape({}).isRequired,
  touched: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  error: false,
  touched: false,
};

const FieldError = ({ name }) => (
  <Field
    name={name}
    subscribe={{
      touched: true,
      error: true,
    }}
    render={
      ({
        input,
        meta: {
          touched,
          error,
          submitError,
        },
      }) => (
        <ErrorMessage
          name={name}
          error={error || submitError}
          touched={touched}
          input={input}
        />
      )
    }
  />
);

FieldError.propTypes = {
  name: PropTypes.string.isRequired,
};

FieldError.defaultProps = {};

export default FieldError;
