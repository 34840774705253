import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ElementorIcon({ className, ...props }) {
  const split = className
    // .replace(/fa(r|s)/, 'fal')
    .replace('fa-', '')
    .split(' ');

  return (
    <i {...props}>
      <FontAwesomeIcon icon={split} />
    </i>
  );
}

ElementorIcon.propTypes = {
  className: PropTypes.string.isRequired,
};
