import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import Slick from 'react-slick';

export default function Slider({ slides }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
  };

  return (
    <div className="slider__box">
      <Slick {...settings}>
        {slides.map((slide) => {
          const { id, altText, title } = slide;
          const { gatsbyImageData } = slide.imageFile.childImageSharp;

          return (
            <div className="slider__slide" key={id}>
              <div className="slider__img">
                <GatsbyImage className="slider__img--giw" alt={altText || title} image={gatsbyImageData} />
              </div>
              <div className="slider__title">
                <h1>
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </h1>
              </div>
            </div>
          );
        })}
      </Slick>
    </div>
  );
}

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
