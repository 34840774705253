import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Teaser({ title, image, alt, acfTitle }) {
  return (
    <div className="teaser__box">
      <GatsbyImage className="teaser__phantom" image={image} alt={alt || title} />
      <div className="teaser__img">
        <GatsbyImage className="teaser__img--giw" alt={alt || title} image={image} />
      </div>
      {acfTitle.alternativeTitleShow && (
        <div className={`teaser__title teaser__title--${acfTitle.alternativeTitlePosition}`}>
          <h1>
            <span dangerouslySetInnerHTML={{ __html: acfTitle.alternativeTitle || title }} />
          </h1>
        </div>
      )}
      <i className="teaser__shadow" />
    </div>
  );
}

Teaser.propTypes = {
  alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({}).isRequired,
  acfTitle: PropTypes.shape({
    alternativeTitle: PropTypes.string,
    alternativeTitlePosition: PropTypes.string.isRequired,
    alternativeTitleShow: PropTypes.bool.isRequired,
  }).isRequired,
};

Teaser.defaultProps = {
  alt: '',
};
