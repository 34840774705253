import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import parser from '../helper/parser';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Teaser from '../components/teaser';
import Slider from '../components/slider';
import Figure from '../components/figure';

import ElementorTabs from '../components/elementor/tabs';
import ElementorSlider from '../components/elementor/slider';
import ElementorToggle from '../components/elementor/toggle';
import ElementorScript from '../components/elementor/script';
import ElementorGallery from '../components/elementor/gallery';
import ElementorCounter from '../components/elementor/counter';
import ElementorContact from '../components/elementor/contact';
import ElementorProgress from '../components/elementor/progress';
import ElementorSectionSlideShow from '../components/elementor/section-slideshow';
import ElementorIcon from '../components/elementor/icon';

import '../assets/icons';

const ExternalLink = ({ href, children, ...props }) => (
  // eslint-disable-next-line
  <a target="_blank" rel="noopener noreferrer" href={href} {...props}>
    {children}
  </a>
);

const EmptyLink = ({ children, ...props }) => (
  // eslint-disable-next-line
  <a {...props}>{children}</a>
);

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const EXTERNAL_CHECK = /^(https?:\/\/|mailto:|tel:)/;

const getProcessor = ({ images, sources, srcUrl }) =>
  parser({
    a: ({ href, ...props }) => {
      if (href && href.indexOf(process.env.GATSBY_SITE_URL) !== 0 && EXTERNAL_CHECK.test(href)) {
        return createElement(ExternalLink, { href, ...props });
      } else if (!href) {
        return createElement(EmptyLink, props);
      }

      switch (href) {
        // return createElement(ElementorModal, { href, modal: 'contact', ...props });
        case '#contact':
        default:
          return createElement(Link, {
            // replace last slash in link and url sate
            to: String(href).replace(/\/$/, '').replace(process.env.GATSBY_SITE_URL, ''),
            ...props,
          });
      }
    },
    script: (props) => createElement(ElementorScript, props),
    img: (rest) => {
      const img = Object.keys(images).find((key) => key.includes(rest.src));

      return images[img]
        ? createElement(Figure, {
            image: images[img],
            ...rest,
          })
        : null;
    },
    span: ({ className, ...props }) => {
      switch (className) {
        case 'elementor-counter-number':
          return createElement(ElementorCounter, { className, ...props });
        default:
          return createElement('span', { className, ...props });
      }
    },
    div: ({ className, ...props }) => {
      switch (className) {
        case 'elementor-contact':
          return createElement(ElementorContact, { className, ...props });
        case 'elementor-toggle':
        case 'elementor-accordion':
          return createElement(ElementorToggle, { className, ...props });
        case 'elementor-image-gallery':
          return createElement(ElementorGallery, {
            className,
            images,
            sources,
            srcUrl,
            ...props,
          });
        case 'elementor-tabs':
          return createElement(ElementorTabs, { className, ...props });
        case 'elementor-progress-bar':
          return createElement(ElementorProgress, { className, ...props });
        default:
          return String(className).includes('elementor-widget-image-carousel')
            ? createElement(ElementorSlider, {
                className,
                images,
                srcUrl,
                ...props,
              })
            : createElement('div', { className, ...props });
      }
    },
    section: ({ className, 'data-settings': dataSettings, ...props }) => {
      if (String(className).includes('elementor-element') && dataSettings && dataSettings.includes('slideshow')) {
        return <ElementorSectionSlideShow className={className} settings={dataSettings} {...props} />;
      }

      return createElement('section', { className, 'data-settings': dataSettings, ...props });
    },
    i: ({ className, ...props }) => createElement(ElementorIcon, { className, ...props }),
  });

const IndexPage = ({
  data: {
    wpgraphql: {
      page: {
        title,
        content,
        elementorImages: images,
        elementorSources: sources,
        featuredImage: media,
        tags,
        acfSeo,
        acfAlternativeTitle,
        acfSlider: { slider },
      },
      generalSettings: { url: srcUrl },
    },
  },
}) => {
  const imgMapping = (images || []).reduce(
    (
      list,
      {
        url,
        link,
        image: {
          childImageSharp: { gatsbyImageData },
        },
      }
    ) => ({
      ...list,
      [url]: { link, ...gatsbyImageData },
    }),
    {}
  );

  const srcMapping = (sources || []).reduce(
    (list, { url, alt, source }) => ({
      ...list,
      [url]: {
        url,
        alt,
        source,
      },
    }),
    {}
  );

  const body =
    content &&
    getProcessor({
      images: imgMapping,
      sources: srcMapping,
      srcUrl,
    }).processSync(
      String(content)
        .replace(new RegExp(srcUrl, 'g'), '')
        .replace(/<!--[^\\[].+-->/g, '')
        .replace(/[\r\n\t]+/g, ' ')
        .replace(/>[\s]+</g, '><')
        .replace(/[\s]+/g, ' ')
        .replace(/"({.*?[^{]})"/g, (a, b) => `"${b.replace(/"/g, '@')}"`)
    ).result;

  return (
    <Layout>
      <Seo
        title={(acfSeo && acfSeo.title) || title}
        description={acfSeo && acfSeo.description}
        keywords={(tags.edges && tags.edges.map(({ name }) => name)) || []}
      />
      {media && (!slider || !slider) && (
        <Teaser
          acfTitle={acfAlternativeTitle}
          alt={media.node.alt_text}
          title={media.node.title || title}
          image={media.node.imageFile.childImageSharp.gatsbyImageData}
        />
      )}
      {slider && <Slider slides={slider} />}
      <main>
        <div className="main__content">{body}</div>
      </main>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const pageQuery = graphql`
  query pageQuery($id: ID!) {
    wpgraphql {
      generalSettings {
        url
      }
      page(id: $id) {
        id
        content
        elementorImages {
          url
          link
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        elementorSources {
          url
          alt
          source {
            publicURL
            id
          }
        }
        elementorData
        slug
        status
        link
        title
        menuOrder
        tags {
          edges {
            node {
              name
              slug
              id
              link
            }
          }
        }
        acfAlternativeTitle {
          alternativeTitle
          alternativeTitlePosition
          alternativeTitleShow
        }
        acfSeo {
          title
          description
        }
        acfSlider {
          slider {
            id
            title
            altText
            description
            sourceUrl
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        featuredImage {
          node {
            id
            slug
            title
            caption
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
