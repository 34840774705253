import React from 'react';
import PropTypes from 'prop-types';

import FieldError from './error';
import classNames from '../../../helper/class-names';


const FieldInput = ({
  input,
  label,
  placeholder,
  disabled,
  required,
  readOnly,
  meta,
  ...props
}) => (
  <div className={classNames('form__field', required && 'form__field--required', input.value && 'form__field--active')}>
    <FieldError name={input.name} />
    <input
      {...props}
      {...input}
      id={input.name}
      className="form__input"
      placeholder={placeholder || label}
      required={required}
      readOnly={readOnly}
      disabled={disabled}
    />
    { label && (<label htmlFor={input.name} className="form__label">{label}</label>) }
  </div>
);

FieldInput.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
};

FieldInput.defaultProps = {
  disabled: false,
  required: false,
  readOnly: false,
  label: null,
  placeholder: '',
  type: 'text',
  autoComplete: 'on',
};

export default FieldInput;
