import unified from 'unified';
import parse from 'rehype-parse';
import rehype2react from 'rehype-react';
import { createElement } from 'react';

export default function parser(components) {
  return unified()
    .use(parse, {
      fragment: true,
    })
    .use(rehype2react, {
      createElement,
      components,
    });
}
