import React from 'react';
import PropTypes from 'prop-types';

import classNames from '../../../helper/class-names';

import FieldError from './error';

export default function FieldSelect({ input, optionList, label, disabled, required, defaultValue }) {
  return (
    <div
      className={classNames('form__field', required && 'form__field--required', input.value && 'form__field--active')}
    >
      <FieldError name={input.name} />
      <select
        {...input}
        id={input.name}
        className="form__select"
        value={input.value || defaultValue}
        disabled={disabled}
      >
        <option style={{ display: 'none' }} key={label} value="">
          {label}
        </option>
        {optionList.map((options, i) => {
          const isKeyValue = typeof options.value !== 'undefined';
          return (
            <option
              key={isKeyValue ? options.value || i : options}
              value={isKeyValue ? options.value : options}
              disabled={isKeyValue ? options.disabled : false}
            >
              {isKeyValue ? options.name : options}
            </option>
          );
        })}
      </select>
      <svg className="form__field-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
      {label && (
        <label htmlFor={input.name} className="form__label">
          {label}
        </label>
      )}
    </div>
  );
}

FieldSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  loading: PropTypes.bool,
  optionList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])).isRequired,
};

FieldSelect.defaultProps = {
  disabled: false,
  required: false,
  loading: false,
  label: null,
  defaultValue: '',
};
