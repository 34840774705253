import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import Lightbox from 'react-image-lightbox';

import useOverlay from '../../hook/overlay';

export default function ElementorGallery({ sources, images, srcUrl, children, className: wrapperClassName }) {
  const nodes = [];
  const titles = [];
  const gallery = useRef(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const {
    props: { className },
  } = children[0];
  const thumbnails = children[0].props.children
    .filter(({ type }) => type !== 'br')
    .map((node, i) => {
      const anchor = node.props.children[0].props.children[0];
      const figure = node.props.children[1] ? node.props.children[1].props.children[0].trim() : null;
      const {
        props: { href },
        key,
      } = anchor;
      const img = anchor.props.children[0];
      const {
        props: { alt, height, width, src },
      } = img;
      const max = Math.max(width, height);
      const thumbnailUrl = `${srcUrl}${src}`;
      const imgHref = `${srcUrl}${href}`;
      const image = images[thumbnailUrl];
      const {
        source: { publicURL },
      } = sources[imgHref];
      if (!image) {
        return null;
      }

      nodes.push(publicURL);
      titles.push(figure);

      return (
        <button
          type="button"
          key={key}
          onClick={() => {
            setPhotoIndex(i);
            setOpen(true);
          }}
          style={{ maxWidth: `${max}px`, maxHeight: `${max}px`, margin: 'auto' }}
        >
          <GatsbyImage image={image} alt={alt} />
          {figure && <span>{figure}</span>}
        </button>
      );
    });

  const prevImage = () => {
    setPhotoIndex((photoIndex + nodes.length - 1) % nodes.length);
  };

  const nextImage = () => {
    setPhotoIndex((photoIndex + 1) % nodes.length);
  };

  const closeGallery = () => {
    setPhotoIndex(0);
    setOpen(false);
  };

  useOverlay(isOpen);

  return (
    <>
      <div ref={gallery} className={wrapperClassName}>
        <div className={className}>{thumbnails}</div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={nodes[photoIndex]}
          nextSrc={nodes[(photoIndex + 1) % nodes.length]}
          prevSrc={nodes[(photoIndex + nodes.length - 1) % nodes.length]}
          images={nodes}
          onMoveNextRequest={nextImage}
          onMovePrevRequest={prevImage}
          onCloseRequest={closeGallery}
          imageTitle={titles[photoIndex]}
        />
      )}
    </>
  );
}

ElementorGallery.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  srcUrl: PropTypes.string.isRequired,
  images: PropTypes.shape({}).isRequired,
  sources: PropTypes.shape({}).isRequired,
};
