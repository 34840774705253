import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const onDragStart = (e) => e.preventDefault();

const Figure = ({ image, alt, className, width, height }) => {
  const style = { maxWidth: `${width}px`, width: '100%', maxHeight: `${height}px`, margin: 'auto', overflow: 'unset' };
  const size = (className || '').replace(/^.*size-/gi, '');

  switch (size) {
    case 'medium':
      const current = Math.max(width, height);
      style.maxWidth = `${current}px`;
      style.maxHeight = `${current}px`;
      style.width = `${current}px`;
      style.height = `${current}px`;
      break;

    case 'fullscreen':
      style.width = '100%';
      style.maxWidth = '100%';
      delete style.maxHeight;
      break;

    case 'dynamic':
      style.width = '100%';
      style.maxWidth = '100%';
      style.maxHeight = '100vh';
      break;

    case 'extra-medium':
      style.maxWidth = '640px';
      style.maxHeight = '640px';
      break;

    default:
  }

  return <GatsbyImage onDragStart={onDragStart} className={className} alt={alt} image={image} style={style} />;
};

Figure.defaultProps = {
  alt: '',
};

Figure.propTypes = {
  image: PropTypes.shape({}).isRequired,
  alt: PropTypes.string,
  className: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Figure;
