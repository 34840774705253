import React from 'react';
import PropTypes from 'prop-types';
import AliceCarousel from 'react-alice-carousel';

/**
 * @tailwind alice-carousel__prev-btn-item
 * @tailwind alice-carousel__next-btn-item
 * @tailwind elementor-slider__300
 * @tailwind elementor-slider__150
 */
export default function ElementorSlider({ children, 'data-settings': settings }) {
  const { props: container } = children[0];
  const { props: wrapper } = container.children[0];
  const { props: carousel } = wrapper.children[0];
  const { children: slides } = carousel;

  let maxWidth = 300;

  const {
    autoplay,
    infinite,
    // direction,
    slides_to_show: rows,
    autoplay_speed: autoplaySpeed,
    // pause_on_hover: pauseOnHover,
  } = JSON.parse(settings);

  const slideImages = slides.map(({ props: slideProps }) => {
    const { props: slide } = slideProps.children[0];
    const { props: image } = slide.children[0];
    const width = parseInt((image.style && image.style.width) || 300, 10);
    maxWidth = Math.min(maxWidth, isNaN(width) ? 300 : width);
    return slide.children;
  });

  const configs = {
    items: slideImages,
    autoPlay: autoplay === 'yes',
    infinite: infinite === 'yes',
    autoPlayInterval: autoplaySpeed,
    dotsDisabled: true,
    mouseDragEnabled: true,
    responsive: {
      0: { items: rows },
    },
  };

  return (
    <div className={`elementor-slider elementor-slider__${maxWidth}`}>
      <AliceCarousel {...configs} />
    </div>
  );
}

ElementorSlider.propTypes = {
  children: PropTypes.node.isRequired,
  'data-settings': PropTypes.string.isRequired,
};
