const { isArray } = Array;
const isEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const htmlChars = {
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&apos;': "'",
  '&amp;': '&',
};
const reversedEscapeChars = Object.keys(htmlChars).reduce((list, char) => ({ ...list, [char]: htmlChars[char] }), {});

const __ = (_) => _;

const MSG = {
  email: __('E-Mail Adresse ist ungültig.'),
  max: __('Maximal erlaubter Wert ist {{max}}'),
  min: __('Minimal erlaubter Wert ist {{min}}'),
  required: __('Pflichtfeld bitte ausfühlen.'),
  regex: __('Ungültige Eingabe.'),
  checked: __('Dieses Feld muss akzeptiert werden.'),
  between: __('Die Eingabe muss zwischen {{min}} und {{max}} liegen.'),
  date: __('Datum ist ungültig.'),
  alpha: __('Erlaubt sind nur Buchstaben.'),
  alnum: __('Erlaubt sind nur Buchstaben und Zahlen.'),
  number: __('Erlaubt sind nur Zahlen.'),
  string: __('Erlaubt sind nur Buchstaben und Zahlen.'),
};

const FILTER = {
  escapeHtml(value) {
    return String(value).replace(/[&<>"']/g, (m) => reversedEscapeChars[m]);
  },

  bool(value) {
    if (typeof value === 'string') {
      return ['true', 'yes', 'on', '1'].indexOf(value.toLowerCase().trim()) >= 0;
    }

    return value === true || value === 1;
  },
};

const VALIDATOR = {
  alpha(value, { space = false }) {
    const regex = space ? /^[a-z -\xDF-\xF6\xF8-\xFF]+$/i : /^[a-z\xDF-\xF6\xF8-\xFF]+$/i;

    return regex.test(value);
  },

  alnum(value, { space = false }) {
    const regex = space ? /^[a-z0-9 -\xDF-\xF6\xF8-\xFF]+$/i : /^[a-z0-9\xDF-\xF6\xF8-\xFF]+$/i;

    return regex.test(value);
  },

  number(value) {
    return /^[0-9,.]+$/.test(value);
  },

  string(value) {
    return typeof value === 'string';
  },

  date(value) {
    return !isNaN(Date.parse(value)); // eslint-disable-line
  },

  required(value) {
    return typeof value !== 'undefined' && value !== null;
  },

  email(value) {
    return isEmail.test(value);
  },

  min(value, { min }) {
    return Number(value) >= min;
  },

  max(value, { max }) {
    return Number(value) <= max;
  },

  between(value, { min, max }) {
    return Number(value) >= min && Number(value) <= max;
  },

  checked(value, { check = true }) {
    return FILTER.bool(value) === check;
  },

  empty(value) {
    let check = false;

    if (Array.isArray(value) && !value.length) {
      check = true;
    } else if (value instanceof Date) {
      check = false;
    } else if (value instanceof Object && !(value instanceof Function) && !Object.keys(value).length) {
      check = true;
    } else {
      check = [undefined, '', null, '0', 0, false].includes(value);
    }

    return check;
  },
};

const IGNORE = ['require', 'empty', '__FUNC'];

class Conform {
  constructor(data) {
    this.__values = JSON.parse(JSON.stringify(data));
    this.__error = [];
    this.__validate = {};
  }

  /**
   * @param {object} validate
   * @return {Conform}
   */
  valid(validate = {}) {
    this.__validate = Object.keys(validate).reduce((list, path) => {
      const toValidate = [].concat(validate[path]);
      const validators = list[path] || {};

      list[path] = toValidate.reduce((vlist, validator) => {
        // eslint-disable-line
        if (typeof validator === 'string') {
          vlist[validator] = {
            // eslint-disable-line
            validator: VALIDATOR[validator],
            msg: MSG[validator],
          };
        } else if (typeof validator === 'function') {
          vlist.__FUNC = (vlist.__FUNC || []).concat({
            // eslint-disable-line
            validator,
          });
        } else if (typeof validator === 'object') {
          const vpath = Object.keys(validator)[0];
          const { msg, ...args } = validator[vpath];

          if (VALIDATOR[vpath]) {
            vlist[vpath] = {
              // eslint-disable-line
              validator: VALIDATOR[vpath],
              msg: msg || MSG[vpath],
              args,
            };
          }
        }

        return vlist;
      }, validators);

      return list;
    }, this.__validate);

    return this;
  }

  /**
   *
   * @param {string} field
   * @return {*}
   */
  getValue(field) {
    const path = isArray(field) ? field : field.split('.');
    return path.reduce(
      (curr, key) => (typeof curr !== 'undefined' && typeof curr[key] !== 'undefined' ? curr[key] : undefined),
      this.__values
    );
  }

  /**
   * @return {Promise<{ value: {Object}, errors: {Object} }>}
   */
  exec() {
    return Object.keys(this.__validate)
      .reduce((rootChain, path) => {
        const validators = this.__validate[path];
        const value = this.getValue(path);
        const fieldChain = Promise.resolve();

        if (validators.required && !VALIDATOR.required(value)) {
          this.__error.push({ error: validators.required.msg, path });
        } else if (validators.empty && VALIDATOR.empty(value)) {
          // no validate if value is empty
        } else if (validators.__FUNC) {
          validators.__FUNC.forEach(({ validator }) => {
            fieldChain
              .then(() => validator && validator.call(this, value, path))
              .then(
                (check) =>
                  check !== true &&
                  this.__error.push({
                    error: Conform.msg({ path, value }, check),
                    path,
                  })
              );
          });
        } else {
          Object.keys(validators)
            .filter((key) => !IGNORE.includes(key))
            .forEach((key) => {
              const { validator, msg, args } = validators[key];
              fieldChain
                .then(() => validator && validator.call(this, value, args || {}))
                .then(
                  (check) =>
                    check !== true &&
                    this.__error.push({
                      error: Conform.msg({ path, value, ...args }, msg),
                      path,
                    })
                );
            });
        }

        return rootChain.then(() => fieldChain.then);
      }, Promise.resolve())
      .then(() => ({
        values: this.__values,
        errors: this.__error.reduce((errors, { path, error }) => {
          const keys = path.split('.');

          keys.reduce((chain, key, i) => {
            chain[key] = i === keys.length - 1 ? chain[key] || [] : chain[key] || {}; // eslint-disable-line
            return chain[key];
          }, errors);

          keys.reduce((chain, key) => chain[key], errors).push(error);

          return errors;
        }, {}),
      }));
  }

  /**
   *
   * @param {object} placeholder
   * @param {string} context
   *
   * @return {string}
   */
  static msg(placeholder, context) {
    return String(context).replace(/\{\{([a-zA-Z_]+)\}\}/g, (p, key) => FILTER.escapeHtml(placeholder[key] || ''));
  }
}

export default function Conforma(data) {
  return new Conform(data);
}
