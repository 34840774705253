import { useEffect } from 'react';

export default function useOverlay(toggle) {
  useEffect(() => {
    const { classList } = document.documentElement;

    if (toggle) {
      classList.add('overlay');
    } else {
      classList.remove('overlay');
    }

    return () => classList.remove('overlay');
  }, [toggle]);
}
