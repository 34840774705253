import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import Conforma from '../../helper/conforma';
import classNames from '../../helper/class-names';

import BlockSuccess from '../block/success';

import FieldText from '../form/field/text';
import FieldInput from '../form/field/input';
import FieldSelect from '../form/field/select';

export default function ElementorContact({ className, ...props }) {
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (values) =>
    fetch(process.env.GATSBY_CONTACT_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: encodeURIComponent(JSON.stringify(values)),
    })
      .then((res) => res.json())
      .then((body) => setSubmitted(true))
      .catch((body) => setSubmitted(true));

  const validate = (values) =>
    Conforma(values)
      .valid({
        email: 'email',
        givenName: 'required',
        salutation: 'required',
        familyName: 'required',
        phone: 'required',
        remark: 'required',
      })
      .exec()
      .then(({ errors }) => errors);

  if (submitted) {
    return (
      <BlockSuccess
        title="Vielen Dank für Ihre Kontaktaufnahme"
        message="Wir werden uns in Kürze mit Ihnen in Verbindung setzen."
      />
    );
  }

  return (
    <div {...props} className={className}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        validateOnBlur
        render={({ submitting, handleSubmit }) => (
          <form autoComplete="nope" noValidate onSubmit={handleSubmit}>
            <div className="form__group">
              <Field
                name="salutation"
                label="Anrede"
                optionList={['Frau', 'Herr', 'Divers']}
                component={FieldSelect}
                required
              />
              <Field name="givenName" label="Vorname" component={FieldInput} required />
              <Field name="familyName" label="Nachname" component={FieldInput} required />
            </div>
            <div className="form__group">
              <Field name="email" label="E-Mail" type="email" component={FieldInput} required />
              <Field name="phone" label="Telefon" type="tel" component={FieldInput} required />
            </div>
            <div className="form__group">
              <Field name="remark" label="Ihr Anliegen" component={FieldText} required />
            </div>
            <div className="form__notice">
              {'Mit dem Absenden stimme ich den '}
              <a href="/datenschutz" target="_blank">
                Datenschutzbestimmungen
              </a>
              {' zu.'}
            </div>
            <div className="form__btn-group">
              <div>
                <button
                  className={classNames('form__btn', 'form__btn--submit', submitting && 'form__btn--loading')}
                  type="submit"
                  disabled={submitting}
                >
                  Absenden
                </button>
              </div>
            </div>
            <div className="form__required">* Pflichtfelder</div>
          </form>
        )}
      />
    </div>
  );
}

ElementorContact.propTypes = {
  className: PropTypes.string.isRequired,
};
