import { library } from '@fortawesome/fontawesome-svg-core';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faFax } from '@fortawesome/free-solid-svg-icons/faFax';
import { faEnvelope as farEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faTelegram } from '@fortawesome/free-brands-svg-icons/faTelegram';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

library.add(
  faMapMarkedAlt,
  faPhone,
  faEnvelope,
  faFacebook,
  faFacebookMessenger,
  faWhatsapp,
  faTelegram,
  faInstagram,
  faHandshake,
  faTimes,
  faChevronDown,
  faCheckCircle,
  faTimesCircle,
  faPlus,
  faEnvelopeOpen,
  faFax,
  farEnvelope,
  faMinus
);
